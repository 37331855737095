import { H as HARVEST_STATUS_TEXTS, a as HARVEST_STATUS_COLORS } from "./harvest.status.js";
import { VIEW_PERMISSION, MAINTAIN_PERMISSION, GROWERS_PADDOCKHARVESTWEIGHT } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-paddock-harvest"
  }, [_vm.isTableRender ? _c("list-composer", {
    attrs: {
      "title": "Paddock Harvest Weight",
      "has-active": false,
      "search-by": "Paddock,PaddockRef,CropCode,CropName,GrowerCode,GrowerName,Status",
      "ph-search-by": "Search by keywords"
    },
    scopedSlots: _vm._u([{
      key: "customFilter",
      fn: function() {
        return [_c("div", {
          staticClass: "status-filter mr-2"
        }, [_c("select-filter", {
          attrs: {
            "source": "value",
            "source-label": "text",
            "data-source": _vm.filterOptions,
            "value": _vm.filterOptions[0]
          },
          on: {
            "change": _vm.filterStatus
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_vm.hasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Update Status")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.updateStatus(record);
            }
          }
        })], 1) : _vm._e(), _vm.hasViewPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link",
            "disabled": record.status === "N" || record.recordObservations === false
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 645667085)
  }, [_c("url-field", {
    key: "Paddock",
    attrs: {
      "data-index": "paddockCode",
      "title": "Paddock Code",
      "sorter": true,
      "base-url": "/watties-grower/paddock-harvest",
      "clickable": false
    }
  }), _c("text-field", {
    key: "PaddockRef",
    attrs: {
      "data-index": "paddockRef",
      "title": "Paddock Ref",
      "sorter": true
    }
  }), _c("text-field", {
    key: "GrowerCode",
    attrs: {
      "data-index": "growerCode",
      "title": "Grower",
      "parse": _vm.parseGrowerCode,
      "sorter": true
    }
  }), _c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code",
      "parse": _vm.parseCropCode,
      "sorter": true
    }
  }), _c("text-field", {
    key: "HarvestWeight",
    attrs: {
      "data-index": "harvestWeight",
      "title": "Harvest Weight",
      "sorter": true
    }
  }), _c("tag-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "color": _vm.getStatusColor,
      "parse": _vm.parseStatus,
      "sorter": true
    }
  })], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      "title": "Edit Status",
      "centered": "",
      "ok-button-props": {
        props: {
          disabled: !_vm.isChangeStatus
        }
      }
    },
    on: {
      "ok": _vm.submitModal
    },
    model: {
      value: _vm.visibleModal,
      callback: function($$v) {
        _vm.visibleModal = $$v;
      },
      expression: "visibleModal"
    }
  }, [_vm.paddockModal ? _c("div", {
    staticClass: "edit-status"
  }, [_c("div", {
    staticClass: "d-flex align-items-center justify-content-between mb-4"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("h4", {
    staticClass: "h4 m-0"
  }, [_vm._v(" Paddock - " + _vm._s(_vm.paddockModal.paddockCode.trim()) + " ")]), _c("p", {
    staticClass: "b7 mb-0"
  }, [_vm._v(_vm._s(_vm.paddockModal.paddockRef))])]), _c("a-select", {
    staticClass: "status-filter",
    attrs: {
      "value": _vm.paddockModal.status
    },
    on: {
      "change": _vm.changeStatus
    }
  }, _vm._l(_vm.filterOptions.filter(function(i) {
    return i.value !== "all";
  }), function(option) {
    return _c("a-select-option", {
      key: option.value,
      attrs: {
        "value": option.value,
        "disabled": option.value === "N"
      }
    }, [_c("span", {
      style: {
        color: option.color
      }
    }, [_vm._v(_vm._s(option.text))])]);
  }), 1)], 1), _c("a-row", {
    attrs: {
      "gutter": [24, 24]
    }
  }, [_c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Grower",
      "disabled": true,
      "value": _vm.paddockModal.growerCode + " " + _vm.paddockModal.growerName,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Crop",
      "disabled": true,
      "value": _vm.paddockModal.cropCode + " - " + _vm.paddockModal.cropName,
      "span": 12
    }
  })], 1)], 1)], 1) : _vm._e()])], 1);
};
var staticRenderFns$1 = [];
var ListPaddockHarvest_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ListPaddockHarvest",
  inject: ["apiUrl", "resourceName", "crud", "can"],
  data() {
    return {
      paddockModal: null,
      visibleModal: false,
      isChangeStatus: false,
      field: "Status",
      filterOptions: [
        {
          value: "all",
          text: "All",
          color: "black"
        },
        {
          value: "N"
        },
        {
          value: "S"
        },
        {
          value: "C"
        }
      ],
      isTableRender: true
    };
  },
  computed: {
    hasViewPermission() {
      return this.can(VIEW_PERMISSION);
    },
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  beforeMount() {
    this.filterOptions.filter((key) => key.value !== "all").map((key) => {
      Object.assign(key, {
        text: HARVEST_STATUS_TEXTS[key.value],
        color: HARVEST_STATUS_COLORS[key.value]
      });
    });
  },
  async mounted() {
    this.isTableRender = false;
    await this.crud.fetchList();
    this.isTableRender = true;
  },
  methods: {
    parseCropCode(value, record) {
      if (!value)
        return "";
      return `${value} ${record.cropName}`;
    },
    parseGrowerCode(value, record) {
      if (!value)
        return "";
      return `${value} - ${record.growerName}`;
    },
    getStatusColor(value) {
      return HARVEST_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return HARVEST_STATUS_TEXTS[value];
    },
    parseTimeIn(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY hh:mm A");
    },
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    },
    filterStatus(statuses) {
      if (statuses.value == "all") {
        this.crud.deleteFilter(this.field);
        this.fetchList();
        return;
      }
      this.crud.setFilter(this.field, statuses);
      this.fetchList();
    },
    createdStatusFilter() {
      this.statusFilter.filter((key) => {
        if (this.entity.status === "S")
          return key.value !== "E";
        if (this.entity.status === "A")
          return key.value === "A";
        return key.value;
      }).map((key) => {
        Object.assign(key, {
          text: HARVEST_STATUS_TEXTS[key.value],
          color: HARVEST_STATUS_COLORS[key.value]
        });
      });
    },
    updateStatus(record) {
      this.paddockModal = {};
      this.isChangeStatus = false;
      this.visibleModal = true;
      this.paddockModal = Object.assign({}, record);
    },
    changeStatus(e) {
      this.isChangeStatus = true;
      this.paddockModal.status = e;
    },
    submitModal() {
      this.axios.put(`${this.apiUrl}/grower/contract-paddocks/${this.paddockModal.id}/status`, { status: this.paddockModal.status }).then(() => {
        this.crud.fetchList();
        this.$notification["success"]({
          message: "Item Updated!"
        });
      }, (err) => console.log(err)).finally(() => {
        this.visibleModal = false;
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "0616a122", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListPaddockHarvest = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-paddock-harvest"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.contract-paddocks",
      "api-url": _vm.apiUrl,
      "edit-route": "/watties-grower/paddock-harvest/:id",
      "filter-route": "/watties-grower/paddock-harvest/advanced-search",
      "page": _vm.page
    }
  }, [_c("list-paddock-harvest")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListPaddockHarvest
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "harvestprocessing",
          title: "Harvest Processing",
          path: ""
        },
        {
          key: "listpaddockharvest",
          title: "Paddock Harvest Weight",
          path: "/watties-grower/paddock-harvest"
        }
      ],
      page: GROWERS_PADDOCKHARVESTWEIGHT
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
