const HARVEST_STATUS_NOT_STARTED = "N";
const HARVEST_STATUS_STARTED = "S";
const HARVEST_STATUS_COMPLETED = "C";
const HARVEST_STATUS_COLORS = {
  [HARVEST_STATUS_NOT_STARTED]: "gray",
  [HARVEST_STATUS_STARTED]: "blue",
  [HARVEST_STATUS_COMPLETED]: "green"
};
const HARVEST_STATUS_TEXTS = {
  [HARVEST_STATUS_NOT_STARTED]: "Not Started",
  [HARVEST_STATUS_STARTED]: "Started",
  [HARVEST_STATUS_COMPLETED]: "Completed"
};
export { HARVEST_STATUS_TEXTS as H, HARVEST_STATUS_COLORS as a };
